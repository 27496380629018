import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { capitalize, formatNumber, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'

export type StackedBarItem = {
  name: string
  value: number
}

type TooltipData = StackedBarItem & {
  color: string
}

const mockData: StackedBarItem[] = [
  { name: 'MyCollectables', value: 5000 },
  { name: 'MyFinances', value: 3000 },
  { name: 'MyProperties', value: 2000 },
  { name: 'MyBelongings', value: 1000 }
]

const emptyData: StackedBarItem[] = [
  { name: 'Mock 1', value: 60 },
  { name: 'Mock 2', value: 25 },
  { name: 'Mock 3', value: 20 },
  { name: 'Mock 4', value: 5 }
]

interface StackedBarChartProps {
  data?: StackedBarItem[]
  unit?: string
  formatter?: (value: string, unit: string) => string
  max?: number
  hiddenUnassigned?: boolean
  unassignedLabel?: string
}

export function StackedBarChart({
  data = [],
  unit = 'USD',
  formatter = (value, unit) => `${unit} ${value}`,
  max,
  hiddenUnassigned = false,
  unassignedLabel
}: StackedBarChartProps) {
  const database = useAuthStore((state) => state.database)
  const { t } = useTranslation()
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.every(({ value }) => value === 0) && max === undefined
  const dataSource = (isEmpty ? emptyData : data.map((item) => ({ ...item, value: Math.abs(item.value) }))).sort(
    (a, b) => b.value - a.value
  )
  const colors = isEmpty ? emptyColors : chartColors
  const sum = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const total = useMemo(() => Math.max(sum, max || 1), [max, sum])
  const toolTipPercent = ((tooltipData?.data.value ?? 0) / total) * 100

  const handleMouseMove = (e: MouseEvent<SVGRectElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <>
      <ResponsiveContainer width={'100%'} height={50}>
        {({ width, height }) => {
          const percent = d3.scaleLinear().domain([0, total]).range([0, width])
          const hasUnassigned = max !== undefined && total > sum
          const unassigned = hasUnassigned ? [{ name: unassignedLabel ?? t('Unassigned'), value: total - sum }] : []
          const BarList = [...dataSource, ...unassigned].map((item, index, array) => ({
            x: percent(d3.sum(array.slice(0, index), (d) => d.value)),
            width: percent(item.value),
            ...item
          }))

          return (
            <svg width={width} height={height}>
              {BarList.map((item, index) => {
                const isUnassigned = [t('Unassigned'), t('Unallocated')].includes(item.name)
                const isHidden = hiddenUnassigned && isUnassigned
                return (
                  <BarItem
                    key={index}
                    x={item.x}
                    width={item.width}
                    color={!isUnassigned ? colors[index % colors.length] : emptyColors[0]}
                    label={capitalize(item.name)}
                    value={formatter(formatNumber(item.value, preferences.numberFormat, { digits: 0 }), unit)}
                    isEmpty={isHidden || isEmpty}
                    onMouseMove={(e) =>
                      handleMouseMove(e, {
                        name: item.name,
                        value: item.value,
                        color: !isUnassigned ? colors[index % colors.length] : emptyColors[0]
                      })
                    }
                    onMouseLeave={hideTooltip}
                  />
                )
              })}
            </svg>
          )
        }}
      </ResponsiveContainer>

      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <div className={'flex items-center justify-between'}>
            <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
              <div className={'my-0.5 h-4 w-4 shrink-0 rounded'} style={{ backgroundColor: tooltipData.data.color }} />
              <TruncatedText as={'span'} className={'text-sm font-bold capitalize text-white'}>
                {tooltipData.data.name}
              </TruncatedText>
            </div>
          </div>
          <div className={'flex items-center justify-between'}>
            {toolTipPercent && (
              <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                {`${toolTipPercent.toFixed(1)}%`}
              </TruncatedText>
            )}
            <span className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
              {formatter(formatNumberShort(tooltipData.data.value), unit)}
            </span>
          </div>
        </Tooltip>
      )}
    </>
  )
}

interface BarItemProps {
  x: number
  width: number
  color: string
  label: string
  value: string
  isEmpty: boolean
  onMouseMove: (e: MouseEvent<SVGRectElement>) => void
  onMouseLeave: () => void
}

const barItemMargin = 8

function BarItem({ x, width, color, label, value, isEmpty, onMouseMove, onMouseLeave }: BarItemProps) {
  const ref = useRef<HTMLParagraphElement>(null)
  const [textWidth, setTextWidth] = useState<number>(0)
  const opacity = useMemo(() => {
    const isOverflow = width < textWidth + barItemMargin * 2
    return isOverflow || isEmpty ? 0 : 1
  }, [isEmpty, textWidth, width])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0]
        setTextWidth(entry.contentRect.width)
      }
    })
    if (ref.current) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <g transform={`translate(${x},0)`}>
      <rect
        className={cn(isEmpty && 'pointer-events-none')}
        width={width}
        height={'100%'}
        fill={color}
        stroke={'#fff'}
        strokeWidth={1}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <foreignObject
        className={'pointer-events-none'}
        width={Math.max(width - barItemMargin * 2, 0)}
        height={'100%'}
        x={barItemMargin}
        opacity={opacity}
      >
        <div className={'flex h-full flex-col items-start justify-center text-[13px] font-bold text-white'}>
          <TruncatedText>{label}</TruncatedText>
          <p className={'whitespace-nowrap'} ref={ref}>
            {value}
          </p>
        </div>
      </foreignObject>
    </g>
  )
}
